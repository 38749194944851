import React, { useEffect } from 'react';

import { HoneyBadgerNumberInput } from './HoneyBadgerNumberInput';
import { useAppSelector } from '../app/hooks';

import { HoneyBadger } from '../types/HoneyBadger';
import HoneyBadgerListItem from './HoneyBadgerListItem';

import sorted from '../config/sorted.json';

interface Props {
  // Define your component props here
}

export const HoneyBadgerChecker: React.FC<Props> = () => {
  const number = useAppSelector((state) => state.honeyBadger.number);

  const [honeyBadger, setHoneyBadger] = React.useState<HoneyBadger | undefined>(
    undefined
  );

  useEffect(() => {
    if (number === undefined) {
      return;
    }

    const fetchHoneyBadger = () => {
      const result = sorted.find(
        (honeyBadger) => '' + honeyBadger.id === '' + number
      );
      setHoneyBadger(result);
    };

    fetchHoneyBadger();
  }, [number]);

  return (
    <div>
      <HoneyBadgerNumberInput />
      {honeyBadger !== undefined && (
        <>
          <h1>The Honey Badger involved:</h1>
          <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-2 gap-y-2'>
            <HoneyBadgerListItem honeyBadger={honeyBadger} />
          </div>
        </>
      )}
    </div>
  );
};

export default HoneyBadgerChecker;
