import { configureStore } from '@reduxjs/toolkit';
import unisatReducer from '../features/unisat/unisatSlice';
import honeyBadgerReducer from '../features/honeyBadger/honeyBadgerSlice';
import btcReducer from '../features/btc/btcSlice';

export const store = configureStore({
  reducer: {
    unisat: unisatReducer,
    honeyBadger: honeyBadgerReducer,
    btc: btcReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
