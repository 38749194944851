import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

export interface UnisatState {
  address?: string;
}

const initialState: UnisatState = {
  address: undefined,
};

export const unisatSlice = createSlice({
  name: 'unisat',
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAddress } = unisatSlice.actions;

export const selectAddress = (state: RootState) => state.unisat.address;

export default unisatSlice.reducer;
