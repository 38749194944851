import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

export interface HoneyBadgerState {
  number?: string;
}

const initialState: HoneyBadgerState = {
  number: undefined,
};

export const honeyBadgerState = createSlice({
  name: 'honeyBadger',
  initialState,
  reducers: {
    setNumber: (state, action: PayloadAction<string>) => {
      state.number = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNumber } = honeyBadgerState.actions;

export const selectNumber = (state: RootState) => state.honeyBadger.number;

export default honeyBadgerState.reducer;
