import React from 'react';

export const AppFooter: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='fixed bottom-0 left-0 z-20 w-full p-4 bg-[#BF832A] border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600'>
      <span className='text-xs md:text-sm sm:text-center'>
        © {currentYear}{' '}
        <a
          href='https://honeybadgers.btcmint.app/'
          className='hover:underline'
          target='_blank'
          rel='noreferrer'
        >
          Honey Badgers
        </a>{' '}
        Scouter. All Rights Reserved.
      </span>
    </footer>
  );
};
