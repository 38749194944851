import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setAddress as setStateAddress } from '../features/unisat/unisatSlice';
import ActionButton from './ActionButton';

interface UnisatConnectProps {
  // Define your component's props here
}

export const UnisatConnect: React.FC<UnisatConnectProps> = (props) => {
  const [unisatInstalled, setUnisatInstalled] = useState(false);
  const [connected, setConnected] = useState(false);

  const address = useAppSelector((state) => state.unisat.address);

  const dispatch = useAppDispatch();

  const selfRef = useRef<{ accounts: string[] }>({
    accounts: [],
  });
  const self = selfRef.current;

  const _setAddress = useCallback(
    (address: string) => {
      dispatch(setStateAddress(address));
    },
    [dispatch]
  );

  const getBasicInfo = useCallback(async () => {
    const unisat = (window as any).unisat;
    const [address] = await unisat.getAccounts();
    _setAddress(address);
  }, [_setAddress]);

  // Add your component logic here
  const handleAccountsChanged = useCallback(
    (_accounts: string[]) => {
      if (self.accounts[0] === _accounts[0]) {
        // prevent from triggering twice
        return;
      }
      self.accounts = _accounts;
      if (_accounts.length > 0) {
        setConnected(true);
        _setAddress(_accounts[0]);
        getBasicInfo();
      } else {
        setConnected(false);
      }
    },
    [_setAddress, getBasicInfo, self]
  );

  useEffect(() => {
    async function checkUnisat() {
      let unisat = (window as any).unisat;

      for (let i = 1; i < 10 && !unisat; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 100 * i));
        unisat = (window as any).unisat;
      }

      if (unisat) {
        setUnisatInstalled(true);
      } else if (!unisat) return;

      unisat.getAccounts().then((accounts: string[]) => {
        handleAccountsChanged(accounts);
      });

      unisat.on('accountsChanged', handleAccountsChanged);

      return () => {
        unisat.removeListener('accountsChanged', handleAccountsChanged);
      };
    }

    checkUnisat().then();
  }, [handleAccountsChanged]);

  if (!unisatInstalled) {
    return (
      <div className=''>
        <a
          className='btn'
          href='https://unisat.io'
          target='_blank'
          rel='noreferrer'
        >
          Install Unisat Wallet
        </a>
      </div>
    );
  }

  const unisat = (window as any).unisat;
  return (
    <div>
      {connected ? (
        <div>Your connected address: {address}</div>
      ) : (
        <div className=''>
          <ActionButton
            label='Connect Unisat Wallet'
            onClick={async () => {
              const result = await unisat.requestAccounts();
              handleAccountsChanged(result);
            }}
            prefix={
              <svg
                fill='#ffffff'
                height='16px'
                width='16px'
                version='1.1'
                id='XMLID_127_'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                stroke='#ffffff'
              >
                <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></g>
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <g id='connect'>
                    {' '}
                    <g>
                      {' '}
                      <path d='M5.9,24c-1.6,0-3.1-0.6-4.2-1.7C0.6,21.2,0,19.7,0,18.1c0-1.6,0.6-3.1,1.7-4.2l3.8-3.8l2,2l2.8-2.8l1.4,1.4l-2.8,2.8 l1.6,1.6l2.8-2.8l1.4,1.4l-2.8,2.8l2,2l-3.7,3.8C9,23.3,7.5,24,5.9,24z M5.5,12.9l-2.3,2.3C2.4,16,2,17,2,18s0.4,2,1.2,2.8 c1.5,1.5,4.1,1.5,5.6,0l2.3-2.4L5.5,12.9z M18.5,13.9l-8.4-8.4l3.7-3.8C14.9,0.6,16.5,0,18,0c1.5,0,3,0.6,4.2,1.7 C23.4,2.8,24,4.3,24,5.9s-0.6,3.1-1.7,4.2L18.5,13.9z M13,5.5l5.5,5.5l2.3-2.3C21.6,7.9,22,7,22,5.9c0-1-0.4-2-1.2-2.8 c-1.5-1.5-4-1.5-5.6,0L13,5.5z'></path>{' '}
                    </g>{' '}
                  </g>{' '}
                </g>
              </svg>
            }
          />
        </div>
      )}
    </div>
  );
};
