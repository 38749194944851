import React, { useEffect, useState } from 'react';

import { listAddressAllInscriptions } from '../utils/ApiHelper';
import { Inscription } from '../types/Inscription';
import HoneyBadgerListItem from './HoneyBadgerListItem';

import sorted from '../config/sorted.json';
import { HoneyBadger } from '../types/HoneyBadger';
import { useAppSelector } from '../app/hooks';
import { UnisatAddressInput } from './UnisatAddressInput';

export const HoneyBadgerList: React.FC = () => {
  const [inscriptions, setInscriptions] = useState([] as Inscription[]);
  const filteredInscriptions = inscriptions.filter((inscription) =>
    sorted.some(
      (hb) => '' + hb.inscriptionNumber === '' + inscription.inscriptionNumber
    )
  );

  const address = useAppSelector((state) => state.btc.address);

  useEffect(() => {
    const loadInscriptions = async () => {
      const accountInscriptions = await listAddressAllInscriptions(address!);
      setInscriptions(accountInscriptions);
    };

    if (address !== undefined) {
      loadInscriptions();
    }
  }, [address]);

  const honeyBadgers: HoneyBadger[] = filteredInscriptions.map(
    (inscription) => {
      return sorted.find(
        (hb) => '' + hb.inscriptionNumber === '' + inscription.inscriptionNumber
      )!;
    }
  );

  const sortedHoneyBadgers = honeyBadgers.sort((a, b) => {
    return b.score - a.score;
  });

  return (
    <div>
      <UnisatAddressInput />
      {address !== undefined && (
        <>
          {honeyBadgers.length === 0 && (
            <h1>No Honey Badgers owned by this address</h1>
          )}
          {honeyBadgers.length > 0 && (
            <>
              <h1>The List of Honey Badgers</h1>
              <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-2 gap-y-2'>
                {sortedHoneyBadgers.map((honeyBadger) => (
                  <div key={honeyBadger.inscriptionNumber}>
                    <HoneyBadgerListItem honeyBadger={honeyBadger} />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
