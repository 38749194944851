import React, { useState } from 'react';
import { AppHeader } from './components/AppHeader';
import { AppFooter } from './components/AppFooter';
import { HoneyBadgerList } from './components/HoneyBadgerList';
import { HoneyBadgerChecker } from './components/HoneyBadgerChecker';
import { ConnectedHoneyBadgerList } from './components/ConnectedHoneyBadgerList';
import unisatLogo from './assets/logo_unisat.png';

function App() {
  const [activeTab, setActiveTab] = useState(0);

  const onTabPressed = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <div className=''>
      <AppHeader />
      <div className='container mx-auto mb-[80px] mt-2'>
        <div className='tabs tabs-lifted mx-2 md:mx-0 mt-2'>
          <button
            className={`tab tab-lifted ${activeTab === 0 && 'tab-active'}`}
            id='tab1'
            onClick={() => onTabPressed(0)}
          >
            <svg
              className='w-4 h-4 me-2 -ms-1'
              aria-hidden='true'
              focusable='false'
              data-prefix='fab'
              data-icon='bitcoin'
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 512 512'
            >
              <path
                fill='currentColor'
                d='M504 256c0 136.1-111 248-248 248S8 392.1 8 256 119 8 256 8s248 111 248 248zm-141.7-35.33c4.937-32.1-20.19-50.74-54.55-62.57l11.15-44.7-27.21-6.781-10.85 43.52c-7.154-1.783-14.5-3.464-21.8-5.13l10.93-43.81-27.2-6.781-11.15 44.69c-5.922-1.349-11.73-2.682-17.38-4.084l.031-.14-37.53-9.37-7.239 29.06s20.19 4.627 19.76 4.913c11.02 2.751 13.01 10.04 12.68 15.82l-12.7 50.92c.76 .194 1.744 .473 2.829 .907-.907-.225-1.876-.473-2.876-.713l-17.8 71.34c-1.349 3.348-4.767 8.37-12.47 6.464 .271 .395-19.78-4.937-19.78-4.937l-13.51 31.15 35.41 8.827c6.588 1.651 13.05 3.379 19.4 5.006l-11.26 45.21 27.18 6.781 11.15-44.73a1038 1038 0 0 0 21.69 5.627l-11.11 44.52 27.21 6.781 11.26-45.13c46.4 8.781 81.3 5.239 95.99-36.73 11.84-33.79-.589-53.28-25-65.99 17.78-4.098 31.17-15.79 34.75-39.95zm-62.18 87.18c-8.41 33.79-65.31 15.52-83.75 10.94l14.94-59.9c18.45 4.603 77.6 13.72 68.81 48.96zm8.417-87.67c-7.673 30.74-55.03 15.12-70.39 11.29l13.55-54.33c15.36 3.828 64.84 10.97 56.85 43.03z'
              ></path>
            </svg>
            By Address
          </button>
          <button
            className={`tab tab-lifted ${activeTab === 1 && 'tab-active'}`}
            id='tab2'
            onClick={() => onTabPressed(1)}
          >
            <svg
              className='w-4 h-4 me-2 -ms-1'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
              <g
                id='SVGRepo_tracerCarrier'
                stroke-linecap='round'
                stroke-linejoin='round'
              ></g>
              <g id='SVGRepo_iconCarrier'>
                {' '}
                <path
                  d='M13 21L17 3'
                  stroke='#323232'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>{' '}
                <path
                  d='M7 21L11 3'
                  stroke='#323232'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>{' '}
                <path
                  d='M20 9L4 9'
                  stroke='#323232'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>{' '}
                <path
                  d='M4 15L20 15'
                  stroke='#323232'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>{' '}
              </g>
            </svg>
            By Honey Badger #
          </button>
          <button
            className={`tab tab-lifted ${activeTab === 2 && 'tab-active'}`}
            id='tab3'
            onClick={() => onTabPressed(2)}
          >
            <img
              src={unisatLogo}
              alt='Unisat Logo'
              className='w-4 h-4 me-2 -ms-1'
            />
            By Unisat Wallet
          </button>
        </div>
        {activeTab === 0 && (
          <div className='bg-base-100 border-base-300 p-2 border mx-2 md:mx-auto border-t-0 rounded-b-lg'>
            <HoneyBadgerList />
          </div>
        )}
        {activeTab === 1 && (
          <div className='bg-base-100 border-base-300 p-2 border mx-2 md:mx-auto border-t-0 rounded-b-lg'>
            <HoneyBadgerChecker />
          </div>
        )}
        {activeTab === 2 && (
          <div className='bg-base-100 border-base-300 p-2 border mx-2 md:mx-auto border-t-0 rounded-b-lg'>
            <ConnectedHoneyBadgerList />
          </div>
        )}
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
