import axios from 'axios';
import { Inscription } from '../types/Inscription';

const UNISAT_API_KEY = process.env.REACT_APP_UNISAT_API_KEY;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data; // do like this
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const listAddressAllInscriptions = async (
  address: string
): Promise<Inscription[]> => {
  try {
    let allInscriptions: Inscription[] = [];
    let cursor = 0;
    let hasReachedEnd = false;
    do {
      const responseData = await axios.get(
        `https://open-api.unisat.io/v1/indexer/address/${address}/inscription-data`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${UNISAT_API_KEY}`,
          },
          params: {
            cursor,
          },
        }
      );
      const { data } = responseData as any;
      const { total } = data as any;
      allInscriptions = [...allInscriptions, ...(data as any).inscription];
      cursor = cursor + (data as any).inscription.length;
      if (allInscriptions.length >= total) {
        hasReachedEnd = true;
      }
    } while (!hasReachedEnd);
    return allInscriptions;
  } catch (err) {
    return [];
  }
};
