import React from 'react';

interface ActionButtonProps {
  label: string;
  onClick: () => void;
  prefix?: JSX.Element;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  label,
  onClick,
  prefix,
}) => {
  return (
    <button className='btn btn-primary text-white rounded-md' onClick={onClick}>
      {prefix !== undefined && prefix}
      {label}
    </button>
  );
};

export default ActionButton;
