import React, { useState } from 'react';

import { useAppDispatch } from '../app/hooks';
import { setNumber as setStateNumber } from '../features/honeyBadger/honeyBadgerSlice';
import ActionButton from './ActionButton';

export const HoneyBadgerNumberInput: React.FC = () => {
  const [number, setNumber] = useState('');

  const dispatch = useAppDispatch();

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    const parsedValue: number = parseInt(value);

    if (isNaN(parsedValue) || parsedValue < 1 || parsedValue > 10000) {
      return;
    }

    setNumber(event.target.value);
  };

  const onLoadPressed = () => {
    if (number === '') {
      return;
    }
    dispatch(setStateNumber(number));
  };

  const onClearPressed = () => {
    setNumber('');
  };

  return (
    <div className='flex gap-2 w-full'>
      <div className='grow join'>
        <input
          type='number'
          id='number'
          placeholder='Input Honey Badger #'
          value={number}
          onChange={handleNumberChange}
          className='input w-full text-xs md:text-base'
        />
        <button className='btn btn-ghost' onClick={onClearPressed}>
          <svg
            className='w-6 h-6 text-gray-800 dark:text-white'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='none'
            viewBox='0 0 24 24'
          >
            <path
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              d='M6 18 17.94 6M18 18 6.06 6'
            />
          </svg>
        </button>
      </div>
      <div>
        <ActionButton
          label='Load'
          onClick={onLoadPressed}
          prefix={
            <svg
              width='16px'
              height='16px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              stroke='#ffffff'
            >
              <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
              <g
                id='SVGRepo_tracerCarrier'
                stroke-linecap='round'
                stroke-linejoin='round'
              ></g>
              <g id='SVGRepo_iconCarrier'>
                {' '}
                <path
                  d='M2 12L5 12'
                  stroke='#ffffff'
                  stroke-width='1.5'
                  stroke-linecap='round'
                ></path>{' '}
                <path
                  d='M19 12L22 12'
                  stroke='#ffffff'
                  stroke-width='1.5'
                  stroke-linecap='round'
                ></path>{' '}
                <path
                  d='M12 22L12 19'
                  stroke='#ffffff'
                  stroke-width='1.5'
                  stroke-linecap='round'
                ></path>{' '}
                <path
                  d='M12 5L12 2'
                  stroke='#ffffff'
                  stroke-width='1.5'
                  stroke-linecap='round'
                ></path>{' '}
                <path
                  d='M10 12H12H14'
                  stroke='#ffffff'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>{' '}
                <path
                  d='M12 14L12 12L12 10'
                  stroke='#ffffff'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>{' '}
                <path
                  d='M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7'
                  stroke='#ffffff'
                  stroke-width='1.5'
                  stroke-linecap='round'
                ></path>{' '}
              </g>
            </svg>
          }
        />
      </div>
    </div>
  );
};
