import React from 'react';
import scouterLogo from '../assets/logo192.png';

export const AppHeader: React.FC = () => {
  return (
    <header className='sticky top-0 bg-gray-800 py-4'>
      <div className='container mx-auto px-4 flex items-center'>
        <img
          src={scouterLogo}
          className='h-[28px]'
          alt='Honey Badgers Scouter'
        />
        <h1 className='text-white text-2xl font-bold'>Honey Badgers Scouter</h1>
      </div>
    </header>
  );
};
