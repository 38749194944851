import React from 'react';
import { HoneyBadger } from '../types/HoneyBadger';

interface HoneyBadgerListItemProps {
  honeyBadger: HoneyBadger;
}

const HoneyBadgerListItem: React.FC<HoneyBadgerListItemProps> = ({
  honeyBadger,
}) => {
  return (
    <div className='flex border'>
      <div className='aspect-square w-[120px]'>
        <img
          src={honeyBadger.contentURI}
          className='w-[120px] h-[120px]'
          alt={`Honey Badger #${honeyBadger.id}`}
        />
      </div>
      <div className='ml-2'>
        <div>Honey Badgers #{honeyBadger.id}</div>
        <h1>Inscription: #{honeyBadger.inscriptionNumber}</h1>
        <div>Rank: {honeyBadger.rank}</div>
        <div>Score: {honeyBadger.score.toFixed(2)}</div>

        <a
          href={`https://honeybadgers.fun/?badger=${honeyBadger.seed}`}
          target='_blank'
          className='flex underline text-xs md:text-base items-center'
          rel='noreferrer'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z'></path>
            <path d='M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z'></path>
          </svg>
          View on HoneyBadgers.fun
        </a>
      </div>
    </div>
  );
};

export default HoneyBadgerListItem;
